exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-complaints-js": () => import("./../../../src/pages/complaints.js" /* webpackChunkName: "component---src-pages-complaints-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-enquiry-form-js": () => import("./../../../src/pages/enquiry-form.js" /* webpackChunkName: "component---src-pages-enquiry-form-js" */),
  "component---src-pages-error-report-js": () => import("./../../../src/pages/error-report.js" /* webpackChunkName: "component---src-pages-error-report-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-legal-notice-js": () => import("./../../../src/pages/legal-notice.js" /* webpackChunkName: "component---src-pages-legal-notice-js" */),
  "component---src-pages-locations-bangkok-js": () => import("./../../../src/pages/locations/bangkok.js" /* webpackChunkName: "component---src-pages-locations-bangkok-js" */),
  "component---src-pages-locations-ho-chi-minh-city-js": () => import("./../../../src/pages/locations/ho-chi-minh-city.js" /* webpackChunkName: "component---src-pages-locations-ho-chi-minh-city-js" */),
  "component---src-pages-locations-hong-kong-js": () => import("./../../../src/pages/locations/hong-kong.js" /* webpackChunkName: "component---src-pages-locations-hong-kong-js" */),
  "component---src-pages-locations-kuala-lumpur-js": () => import("./../../../src/pages/locations/kuala-lumpur.js" /* webpackChunkName: "component---src-pages-locations-kuala-lumpur-js" */),
  "component---src-pages-our-services-citizenship-by-investment-js": () => import("./../../../src/pages/our-services/citizenship-by-investment.js" /* webpackChunkName: "component---src-pages-our-services-citizenship-by-investment-js" */),
  "component---src-pages-our-services-currency-exchange-js": () => import("./../../../src/pages/our-services/currency-exchange.js" /* webpackChunkName: "component---src-pages-our-services-currency-exchange-js" */),
  "component---src-pages-our-services-employee-benefits-js": () => import("./../../../src/pages/our-services/employee-benefits.js" /* webpackChunkName: "component---src-pages-our-services-employee-benefits-js" */),
  "component---src-pages-our-services-estate-planning-js": () => import("./../../../src/pages/our-services/estate-planning.js" /* webpackChunkName: "component---src-pages-our-services-estate-planning-js" */),
  "component---src-pages-our-services-estate-planning-trusts-and-gifting-js": () => import("./../../../src/pages/our-services/estate-planning/trusts-and-gifting.js" /* webpackChunkName: "component---src-pages-our-services-estate-planning-trusts-and-gifting-js" */),
  "component---src-pages-our-services-estate-planning-wills-js": () => import("./../../../src/pages/our-services/estate-planning/wills.js" /* webpackChunkName: "component---src-pages-our-services-estate-planning-wills-js" */),
  "component---src-pages-our-services-financial-advice-js": () => import("./../../../src/pages/our-services/financial-advice.js" /* webpackChunkName: "component---src-pages-our-services-financial-advice-js" */),
  "component---src-pages-our-services-insurance-critical-illness-js": () => import("./../../../src/pages/our-services/insurance/critical-illness.js" /* webpackChunkName: "component---src-pages-our-services-insurance-critical-illness-js" */),
  "component---src-pages-our-services-insurance-income-protection-js": () => import("./../../../src/pages/our-services/insurance/income-protection.js" /* webpackChunkName: "component---src-pages-our-services-insurance-income-protection-js" */),
  "component---src-pages-our-services-insurance-income-protections-js": () => import("./../../../src/pages/our-services/insurance/income-protections.js" /* webpackChunkName: "component---src-pages-our-services-insurance-income-protections-js" */),
  "component---src-pages-our-services-insurance-js": () => import("./../../../src/pages/our-services/insurance.js" /* webpackChunkName: "component---src-pages-our-services-insurance-js" */),
  "component---src-pages-our-services-insurance-life-insurance-js": () => import("./../../../src/pages/our-services/insurance/life-insurance.js" /* webpackChunkName: "component---src-pages-our-services-insurance-life-insurance-js" */),
  "component---src-pages-our-services-insurance-universal-life-insurance-js": () => import("./../../../src/pages/our-services/insurance/universal-life-insurance.js" /* webpackChunkName: "component---src-pages-our-services-insurance-universal-life-insurance-js" */),
  "component---src-pages-our-services-investments-asset-portfolio-management-js": () => import("./../../../src/pages/our-services/investments/asset-portfolio-management.js" /* webpackChunkName: "component---src-pages-our-services-investments-asset-portfolio-management-js" */),
  "component---src-pages-our-services-investments-education-planning-js": () => import("./../../../src/pages/our-services/investments/education-planning.js" /* webpackChunkName: "component---src-pages-our-services-investments-education-planning-js" */),
  "component---src-pages-our-services-investments-investing-internationally-js": () => import("./../../../src/pages/our-services/investments/investing-internationally.js" /* webpackChunkName: "component---src-pages-our-services-investments-investing-internationally-js" */),
  "component---src-pages-our-services-investments-js": () => import("./../../../src/pages/our-services/investments.js" /* webpackChunkName: "component---src-pages-our-services-investments-js" */),
  "component---src-pages-our-services-investments-local-investments-js": () => import("./../../../src/pages/our-services/investments/local-investments.js" /* webpackChunkName: "component---src-pages-our-services-investments-local-investments-js" */),
  "component---src-pages-our-services-js": () => import("./../../../src/pages/our-services.js" /* webpackChunkName: "component---src-pages-our-services-js" */),
  "component---src-pages-our-services-mortgages-js": () => import("./../../../src/pages/our-services/mortgages.js" /* webpackChunkName: "component---src-pages-our-services-mortgages-js" */),
  "component---src-pages-our-services-pensions-js": () => import("./../../../src/pages/our-services/pensions.js" /* webpackChunkName: "component---src-pages-our-services-pensions-js" */),
  "component---src-pages-our-services-property-investments-js": () => import("./../../../src/pages/our-services/property-investments.js" /* webpackChunkName: "component---src-pages-our-services-property-investments-js" */),
  "component---src-pages-our-services-retirement-planning-js": () => import("./../../../src/pages/our-services/retirement-planning.js" /* webpackChunkName: "component---src-pages-our-services-retirement-planning-js" */),
  "component---src-pages-our-services-retirement-planning-pension-transfers-js": () => import("./../../../src/pages/our-services/retirement-planning/pension-transfers.js" /* webpackChunkName: "component---src-pages-our-services-retirement-planning-pension-transfers-js" */),
  "component---src-pages-our-services-retirement-planning-pensions-js": () => import("./../../../src/pages/our-services/retirement-planning/pensions.js" /* webpackChunkName: "component---src-pages-our-services-retirement-planning-pensions-js" */),
  "component---src-pages-our-services-retirement-planning-uk-state-pension-js": () => import("./../../../src/pages/our-services/retirement-planning/uk-state-pension.js" /* webpackChunkName: "component---src-pages-our-services-retirement-planning-uk-state-pension-js" */),
  "component---src-pages-our-services-wealth-management-js": () => import("./../../../src/pages/our-services/wealth-management.js" /* webpackChunkName: "component---src-pages-our-services-wealth-management-js" */),
  "component---src-pages-privacy-notice-js": () => import("./../../../src/pages/privacy-notice.js" /* webpackChunkName: "component---src-pages-privacy-notice-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-who-we-are-about-us-js": () => import("./../../../src/pages/who-we-are/about-us.js" /* webpackChunkName: "component---src-pages-who-we-are-about-us-js" */),
  "component---src-pages-who-we-are-introducing-the-holborn-app-js": () => import("./../../../src/pages/who-we-are/introducing-the-holborn-app.js" /* webpackChunkName: "component---src-pages-who-we-are-introducing-the-holborn-app-js" */),
  "component---src-pages-who-we-are-news-js": () => import("./../../../src/pages/who-we-are/news.js" /* webpackChunkName: "component---src-pages-who-we-are-news-js" */),
  "component---src-pages-who-we-are-our-locations-js": () => import("./../../../src/pages/who-we-are/our-locations.js" /* webpackChunkName: "component---src-pages-who-we-are-our-locations-js" */),
  "component---src-pages-who-we-are-our-locations-thailand-js": () => import("./../../../src/pages/who-we-are/our-locations/thailand.js" /* webpackChunkName: "component---src-pages-who-we-are-our-locations-thailand-js" */),
  "component---src-pages-who-we-are-our-people-bangkok-js": () => import("./../../../src/pages/who-we-are/our-people/bangkok.js" /* webpackChunkName: "component---src-pages-who-we-are-our-people-bangkok-js" */),
  "component---src-pages-who-we-are-our-people-executive-team-js": () => import("./../../../src/pages/who-we-are/our-people/executive-team.js" /* webpackChunkName: "component---src-pages-who-we-are-our-people-executive-team-js" */),
  "component---src-pages-who-we-are-our-people-ho-chi-minh-city-js": () => import("./../../../src/pages/who-we-are/our-people/ho-chi-minh-city.js" /* webpackChunkName: "component---src-pages-who-we-are-our-people-ho-chi-minh-city-js" */),
  "component---src-pages-who-we-are-our-people-hong-kong-js": () => import("./../../../src/pages/who-we-are/our-people/hong-kong.js" /* webpackChunkName: "component---src-pages-who-we-are-our-people-hong-kong-js" */),
  "component---src-pages-who-we-are-our-people-js": () => import("./../../../src/pages/who-we-are/our-people.js" /* webpackChunkName: "component---src-pages-who-we-are-our-people-js" */),
  "component---src-pages-who-we-are-our-people-kuala-lumpur-js": () => import("./../../../src/pages/who-we-are/our-people/kuala-lumpur.js" /* webpackChunkName: "component---src-pages-who-we-are-our-people-kuala-lumpur-js" */),
  "component---src-pages-who-we-are-our-people-operational-teams-js": () => import("./../../../src/pages/who-we-are/our-people/operational-teams.js" /* webpackChunkName: "component---src-pages-who-we-are-our-people-operational-teams-js" */),
  "component---src-pages-who-we-are-our-people-wealth-managers-js": () => import("./../../../src/pages/who-we-are/our-people/wealth-managers.js" /* webpackChunkName: "component---src-pages-who-we-are-our-people-wealth-managers-js" */),
  "component---src-pages-who-we-are-our-team-js": () => import("./../../../src/pages/who-we-are/our-team.js" /* webpackChunkName: "component---src-pages-who-we-are-our-team-js" */),
  "component---src-pages-who-we-are-your-local-offices-js": () => import("./../../../src/pages/who-we-are/your-local-offices.js" /* webpackChunkName: "component---src-pages-who-we-are-your-local-offices-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-office-template-js": () => import("./../../../src/templates/officeTemplate.js" /* webpackChunkName: "component---src-templates-office-template-js" */),
  "component---src-templates-single-employee-js": () => import("./../../../src/templates/SingleEmployee.js" /* webpackChunkName: "component---src-templates-single-employee-js" */),
  "component---src-templates-single-news-press-js": () => import("./../../../src/templates/singleNewsPress.js" /* webpackChunkName: "component---src-templates-single-news-press-js" */)
}

